import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-custom-pls',
  templateUrl: './custom-pls.component.html',
  styleUrls: ['./custom-pls.component.scss']
})
export class CustomPlsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
