import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-code-lookup',
  templateUrl: './code-lookup.component.html',
  styleUrls: ['./code-lookup.component.scss']
})
export class CodeLookupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
