/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContributedDataRow { 
    FromLocationId?: number;
    ToLocationId?: number;
    FromLocation?: string | null;
    ToLocation?: string | null;
    CurrentReleaseYear?: number;
    CurrentReleaseMonth?: number;
    D_8?: number;
    R_8?: number;
    A_8?: number;
    D_8_ALL?: number;
    R_8_ALL?: number;
    A_8_ALL?: number;
    D_7?: number;
    R_7?: number;
    A_7?: number;
    D_7_ALL?: number;
    R_7_ALL?: number;
    A_7_ALL?: number;
    D_6?: number;
    R_6?: number;
    A_6?: number;
    D_6_ALL?: number;
    R_6_ALL?: number;
    A_6_ALL?: number;
    D_5?: number;
    R_5?: number;
    A_5?: number;
    D_5_ALL?: number;
    R_5_ALL?: number;
    A_5_ALL?: number;
    D_4?: number;
    R_4?: number;
    A_4?: number;
    D_4_ALL?: number;
    R_4_ALL?: number;
    A_4_ALL?: number;
    D_3?: number;
    R_3?: number;
    A_3?: number;
    D_3_ALL?: number;
    R_3_ALL?: number;
    A_3_ALL?: number;
    D_2?: number;
    R_2?: number;
    A_2?: number;
    D_2_ALL?: number;
    R_2_ALL?: number;
    A_2_ALL?: number;
    D_1?: number;
    R_1?: number;
    A_1?: number;
    D_1_ALL?: number;
    R_1_ALL?: number;
    A_1_ALL?: number;
    D_0?: number;
    R_0?: number;
    A_0?: number;
    D_0_ALL?: number;
    R_0_ALL?: number;
    A_0_ALL?: number;
}

