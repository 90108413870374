import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-estimating-tier3',
  templateUrl: './tier3.component.html',
  styleUrls: ['./tier3.component.scss']
})
export class Tier3Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
