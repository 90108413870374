import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { PageNotAllowedComponent } from '../../shared/components/page-not-allowed/page-not-allowed.component'
import { PageNotFoundComponent } from '../../shared/components/page-not-found/page-not-found.component'
import { DownloadComponent } from './components/download/download.component'
import { DownloadsComponent } from './components/downloads/downloads.component'
import { FavouritesComponent } from './components/favourites/favourites.component'
import { SelectComponent } from './components/select/select.component'

const downloadRoutes: Routes = [
  {
    path: '',
    component: DownloadComponent,
    children: [
      {
        path: 'select',
        title: 'Select',
        component: SelectComponent
      },
      {
        path: 'favourites',
        title: 'Favourites',
        component: FavouritesComponent
      },
      {
        path: 'downloads',
        title: 'Downloads',
        component: DownloadsComponent
      },

      { path: '', redirectTo: 'select', pathMatch: 'full' },
      { path: 'not-allowed', component: PageNotAllowedComponent },
      { path: '**', component: PageNotFoundComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(downloadRoutes)],
  exports: [RouterModule]
})
export class DownloadRoutingModule {}
