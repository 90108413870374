import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AuthConfig, AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular'
import { CedarWldlApiModule, Configuration, ConfigurationParameters } from '@cts/cedar-wldl-api'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { APP_CONFIG as config } from 'projects/cedar-shared/src/app-config/app-config'
import { API_BASE_PATH } from 'projects/cedar-shared/src/interceptors/API_BASE_PATH'
import { ImpersonationInterceptor } from 'projects/cedar-shared/src/interceptors/impersonation.interceptor'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { WldlSharedModule } from './shared/wldl-shared.module'

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: config.wldlApiBasePath
  }
  console.log(params)
  return new Configuration(params)
}

var authConfig: AuthConfig = {
  ...config.authentication,
  httpInterceptor: {
    allowedList: [`${config.wldlApiBasePath}/*`]
  }
}

@NgModule({
  imports: [
    CedarWldlApiModule.forRoot(apiConfigFactory),
    AuthModule.forRoot(authConfig),
    BrowserModule,
    AppRoutingModule,
    WldlSharedModule,
    NgbModule
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: ImpersonationInterceptor,
      multi: true
    },
    {
      provide: API_BASE_PATH,
      useValue: config.wldlApiBasePath
    },
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
