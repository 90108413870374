import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-auto-ncl-submissions',
  templateUrl: './auto-ncl-submissions.component.html',
  styleUrls: ['./auto-ncl-submissions.component.scss']
})
export class AutoNclSubmissionsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
