import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-tier1-volume',
  templateUrl: './tier1-volume.component.html',
  styleUrls: ['./tier1-volume.component.scss']
})
export class Tier1VolumeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
