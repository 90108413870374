<div [ngSwitch]="authenticationState">
  <!-- the busy spinner page-->
  <div *ngSwitchCase="AuthenticationState.BUSY" style="padding: 200px" [appIsLoading]="true">
    Please wait...
  </div>

  <!-- the logged out page-->
  <div *ngSwitchCase="AuthenticationState.LOGGED_OUT" style="padding: 200px">
    Not logged in.
    <a (click)="logIn()" type="button" class="link-no-href" tabindex="0">Log In</a>
  </div>

  <!-- the logged in page -->
  <div *ngSwitchDefault id="header-bar">
    <div style="float: right">
      <div style="float: right; margin-right: 10px">
        <img src="/assets/img/cts-logo.png" alt="Cedar (home)" style="width: 70px; float: right" />
      </div>
      <div style="float: right; margin-right: 10px">
        <app-header-menu [routes]="routes"></app-header-menu>
      </div>
    </div>

    <div>
      <div><a class="navbar-brand" id="app-title" href="/" title="Cedar (home)">CEDAR</a></div>
      <div style="width: 100%; float: left">
        <div style="float: left; margin-right: 10px">Version |</div>
        <div style="float: left; margin-right: 10px">Environment |</div>
        <div style="float: left; margin-right: 10px">
          <a href="/assets/doc/WLDLUserGuide.pdf" target="cts-doc">User Guide</a>
        </div>
        <div style="float: left">
          <ng-container *ngTemplateOutlet="liveContextTemplate"></ng-container>
        </div>
      </div>
    </div>

    <div style="clear: both"></div>
    <div class="router-outlet" aria-live="polite">
      <main>
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</div>

<!-- the "Logged In as" display for regular logged in users -->
<ng-template #loggedInContextTemplate>
  <div style="float: right; margin-bottom: 10px">
    <a (click)="logOut()" class="link-no-href" tabindex="0">Log Out</a>
  </div>
  <div style="float: right; margin-right: 10px">Logged in as {{ userDescription }} |</div>
</ng-template>

<!-- the "Impersonating" display for impersonated users -->
<ng-template #impersonatingContextTemplate>
  <div style="float: right; margin-bottom: 10px">
    <a (click)="revertImpersonation()" class="link-no-href" type="button" role="button" tabindex="0"
      >Revert</a
    >
  </div>
  <div style="float: right; margin-right: 10px">Impersonating {{ userDescription }} |</div>
</ng-template>
