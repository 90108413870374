import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-latest-volumes',
  templateUrl: './latest-volumes.component.html',
  styleUrls: ['./latest-volumes.component.scss']
})
export class LatestVolumesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
