/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Tier1Tier2VolumeDataRow { 
    Year?: number;
    MonthNum?: number;
    QuarterNum?: number;
    IsYearTotal?: boolean;
    VAR_Y0?: boolean;
    TEU_Y0?: number;
    VAR_Y1?: boolean;
    TEU_Y1?: number;
    TEU_SL_Y0?: number;
    TEU_SL_Y1?: number;
    TEU_WL_Y0?: number;
    TEU_WL_Y1?: number;
    PriceIndex_Y0?: number;
    PriceIndex_Y1?: number;
    Y1MonthHasData?: boolean;
    TEU_ChangePct?: number;
    TEU_SL_Y0_Pct?: number;
    TEU_SL_Y1_Pct?: number;
    TEU_WL_Y0_Pct?: number;
    TEU_WL_Y1_Pct?: number;
    WL_PctChange?: number;
    IsMonth?: boolean;
    IsQuarter?: boolean;
}

