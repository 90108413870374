import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
