import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-volume-data-by-trade',
  templateUrl: './by-trade.component.html',
  styleUrls: ['./by-trade.component.scss']
})
export class ByTradeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
