/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ShippingLineDto { 
    Id?: number;
    BusinessContextId?: number | null;
    Name?: string | null;
    DisplayName?: string | null;
    IsDisplayed?: boolean;
    Code?: string | null;
    EmailDomain?: string | null;
    IsFullyContributing?: boolean;
    IsNCL?: boolean;
    IsActive?: boolean;
    Ignore?: boolean;
    VolumesOnly?: boolean;
    GeneratePriceIndices?: boolean;
    CreatedDate?: string;
    CreatedBy?: string | null;
    UpdatedDate?: string;
    UpdatedBy?: string | null;
}

