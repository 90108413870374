/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Tier3SMDataRow { 
    SelectedContainerType?: string | null;
    LoadDate?: string;
    FromLocationId?: number;
    FromName?: string | null;
    Country?: string | null;
    FromIsOtherLocation?: boolean;
    BusinessContextId?: number;
    ShippingLineId?: number;
    SafetyMechanismId?: number | null;
    DataReleaseDate?: string | null;
    AccessRestricted?: boolean;
    TEUValue?: number;
    TEU?: number;
    TEU_Dry?: number;
    TEU_Reefer?: number;
    TEU_SL?: number;
    TEU_SL_Dry?: number;
    TEU_SL_Reefer?: number;
}

