import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Route } from '@angular/router'

@Component({
  selector: 'app-volume-data',
  templateUrl: './volume-data.component.html',
  styleUrls: ['./volume-data.component.scss']
})
export class VolumeDataComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  public get routes(): Route[] {
    return this.route.routeConfig?.children || []
  }
  ngOnInit(): void {}
}
