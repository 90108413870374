import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-tier4-detail',
  templateUrl: './tier4-detail.component.html',
  styleUrls: ['./tier4-detail.component.scss']
})
export class Tier4DetailComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
