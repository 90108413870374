/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ImportedXmlFileSummary { 
    XmlImportFileId?: number;
    Filename?: string | null;
    ShippingLineId?: number;
    ShippingLineName?: string | null;
    LoadYear?: number;
    LoadMonth?: number;
    NumRows?: number;
    ImportedTEU?: number;
    RejectedTEU?: number;
    RejectedPct?: number;
    LocationMappedPct?: number;
}

