import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-top-tens',
  templateUrl: './top-tens.component.html',
  styleUrls: ['./top-tens.component.scss']
})
export class TopTensComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
