import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { WldlSharedModule } from '../../shared/wldl-shared.module'
import { PriceIndicesComponent } from './components/price-indices/price-indices.component'
import { PriceIndicesRoutingModule } from './price-indices-routing.module'

@NgModule({
  imports: [CommonModule, PriceIndicesRoutingModule, WldlSharedModule],
  declarations: [PriceIndicesComponent]
})
export class PriceIndicesModule {}
