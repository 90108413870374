import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { PageNotAllowedComponent } from '../../shared/components/page-not-allowed/page-not-allowed.component'
import { PageNotFoundComponent } from '../../shared/components/page-not-found/page-not-found.component'
import { ByTradeComponent } from './components/by-trade/by-trade.component'
import { DownloadComponent } from './components/download/download.component'
import { LatestVolumesComponent } from './components/latest-volumes/latest-volumes.component'
import { Tier1Component } from './components/tier1/tier1.component'
import { Tier2Component } from './components/tier2/tier2.component'
import { Tier3Component } from './components/tier3/tier3.component'
import { Tier4Component } from './components/tier4/tier4.component'
import { VolumeDataComponent } from './components/volume-data/volume-data.component'
import { ImportExportComponent } from './import-export/import-export.component'

const volumeDataRoutes: Routes = [
  {
    path: '',
    component: VolumeDataComponent,
    children: [
      {
        path: 'latest-volumes',
        title: 'Latest Volumes',
        component: LatestVolumesComponent
      },
      {
        path: 'by-trade',
        title: 'By Trade',
        component: ByTradeComponent
      },
      {
        path: 'tier1',
        title: 'Tier 1',
        component: Tier1Component
      },
      {
        path: 'tier2',
        title: 'Tier 2',
        component: Tier2Component
      },
      {
        path: 'tier3',
        title: 'Tier 3',
        component: Tier3Component
      },
      {
        path: 'tier4',
        title: 'Tier 4',
        component: Tier4Component
      },
      {
        path: 'import-export',
        title: 'Import/Export',
        component: ImportExportComponent
      },
      {
        path: 'download',
        title: 'Download',
        component: DownloadComponent
      },

      { path: '', redirectTo: 'latest-volumes', pathMatch: 'full' },
      { path: 'not-allowed', component: PageNotAllowedComponent },
      { path: '**', component: PageNotFoundComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(volumeDataRoutes)],
  exports: [RouterModule]
})
export class VolumeDataRoutingModule {}
