import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { PageNotAllowedComponent } from '../../shared/components/page-not-allowed/page-not-allowed.component'
import { PageNotFoundComponent } from '../../shared/components/page-not-found/page-not-found.component'
import { AccessRestrictionsComponent } from './components/access-restrictions/access-restrictions.component'
import { CtsAdminComponent } from './components/cts-admin/cts-admin.component'
import { CustomersComponent } from './components/customers/customers.component'
import { ImportedDataComponent } from './components/imported-data/imported-data.component'
import { LatestComponent } from './components/latest/latest.component'
import { PreReleaseComponent } from './components/pre-release/pre-release.component'
import { ReportingLocationsComponent } from './components/reporting-locations/reporting-locations.component'
import { SubmissionsComponent } from './components/submissions/submissions.component'
import { Tier1VolumeComponent } from './components/tier1-volume/tier1-volume.component'
import { TopTensComponent } from './components/top-tens/top-tens.component'
import { UserAccountsComponent } from './components/user-accounts/user-accounts.component'

const ctsAdminRoutes: Routes = [
  {
    path: '',
    component: CtsAdminComponent,
    children: [
      {
        path: 'latest',
        title: 'Latest',
        component: LatestComponent
      },
      {
        path: 'user-accounts',
        title: 'User Accounts',
        component: UserAccountsComponent
      },
      {
        path: 'customers',
        title: 'Customers',
        component: CustomersComponent
      },
      {
        path: 'reporting-locations',
        title: 'Reporting Locations',
        component: ReportingLocationsComponent
      },
      {
        path: 'imported-data',
        title: 'Imported Data',
        component: ImportedDataComponent
      },
      {
        path: 'submissions',
        title: 'Submissions',
        component: SubmissionsComponent
      },
      {
        path: 'tier-1-volume',
        title: 'Tier1 Volume',
        component: Tier1VolumeComponent
      },
      {
        path: 'pre-release',
        title: 'Pre-Release',
        component: PreReleaseComponent
      },
      {
        path: 'top-tens',
        title: 'Top Tens',
        component: TopTensComponent
      },
      {
        path: 'access-restrictions',
        title: 'Access Restrictions',
        component: AccessRestrictionsComponent
      },

      { path: '', redirectTo: 'latest', pathMatch: 'full' },
      { path: 'not-allowed', component: PageNotAllowedComponent },
      { path: '**', component: PageNotFoundComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(ctsAdminRoutes)],
  exports: [RouterModule]
})
export class CtsAdminRoutingModule {}
