import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { BaseResponse, LoginService, UserAccountDto } from '@cts/cedar-wldl-api'
import { AccountType, APP_CONFIG } from 'projects/cedar-shared/src/app-config/app-config'
import { ImpersonationInterceptor } from 'projects/cedar-shared/src/interceptors/impersonation.interceptor'
import { catchError, map, mergeMap, NEVER, of, switchMap } from 'rxjs'

import { EventBus } from '../event-bus/event-bus'
import { AuthenticationStateChangedEvent, UserModelChangedEvent } from '../event-bus/events'
import { AuthenticationState } from './models/authentication-state'
import { PublicConfiguration } from './models/public-configuration.model'
import { UserModel } from './models/user.model'
import { UserRole } from './models/user-role'
import { WldlConfiguration } from './models/wldl-configuration.model'

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
  constructor(
    private readonly authService: AuthService,
    private readonly profileService: LoginService,
    private readonly eventBus: EventBus,
    private readonly impersonationManager: ImpersonationInterceptor
  ) {
    authService.isAuthenticated$
      .pipe(
        map((isAuthenticated) => {
          if (window.location.pathname.toLowerCase() === '/auth-sso') {
            impersonationManager.setImpersonationKeyFromQuery()
            window.location.replace('/')
            if (!isAuthenticated) {
              this.authService.loginWithRedirect()
            }
          }
          return isAuthenticated
        }),

        switchMap((isAuthenticated) => {
          if (isAuthenticated) {
            return this.authService.getAccessTokenSilently()
          } else {
            return of(null)
          }
        }),

        catchError(() => this.authService.loginWithRedirect()),

        switchMap((token) => {
          if (!token) {
            this.publishAuthStateChange(AuthenticationState.LOGGED_OUT)
            this.publishUserModelChange(null)
          } else {
            this.publishAuthStateChange(AuthenticationState.BUSY)
            return of(void 0)
          }
          return NEVER
        }),

        mergeMap((_) => {
          return profileService.getLogin()
        })
      )
      .subscribe({
        next: (baseResponse) => {
          var accountType = baseResponse.Context?.ViewContext?.AccountType as AccountType
          if (!APP_CONFIG.application.accountTypes.includes(accountType)) {
            this.logOut()
          }

          this.publishUserModelChange(baseResponse)
          this.publishAuthStateChange(
            this.impersonationManager.isImpersonating
              ? AuthenticationState.IMPERSONATION_STARTED
              : AuthenticationState.LOGGED_IN
          )
        },
        error: (error: HttpErrorResponse) => {
          if (
            error.status == HttpStatusCode.BadRequest &&
            this.impersonationManager.isImpersonating
          ) {
            this.revertImpersonation()
          }
        }
      })

    eventBus.handle({
      'user-profile-updated': () => {
        this.profileService
          .getLogin()
          .subscribe((baseResponse) => this.publishUserModelChange(baseResponse))
      }
    })
  }

  logIn(): void {
    this.authService.loginWithRedirect()
  }

  logOut(): void {
    this.authService.logout({ logoutParams: { returnTo: APP_CONFIG.logoutUri } })
  }

  startImpersonation(userId: number, application: 'W' | 'P') {
    var uri: string
    switch (application) {
      case 'W':
        uri = APP_CONFIG.wldlAppUri
        break
      case 'P':
        uri = APP_CONFIG.publicAppUri
        break
      default:
        throw new Error(`Don't know what to do with application type ${application}`)
    }
    document.location.href = `${uri}?${this.impersonationManager.userIdQueryParam}=${userId}`
  }

  revertImpersonation() {
    this.impersonationManager.clearActorId()
    document.location.href = APP_CONFIG.adminAppUri
  }

  private static getUserModel(baseResponse: BaseResponse): UserModel {
    var context = baseResponse.Context?.ViewContext

    if (!context) {
      throw new Error(`Missing view context in response argument`)
    }

    var homeRoute: string
    var roles: UserRole[] = []
    var publicConfiguration: PublicConfiguration = PublicConfiguration.none
    var wldlConfiguration: WldlConfiguration = WldlConfiguration.none
    switch (context.AccountType) {
      case 'P':
        throw new Error('not implemented')

      case 'W':
        roles.push(UserRole.Wldl)
        homeRoute = 'my-account'
        wldlConfiguration = this.buildWldlConfiguration(context)
        break

      case 'C':
        roles.push(UserRole.CtsAdministrator)
        homeRoute = 'cts-admin'
        break

      default:
        throw new Error(`Don't know what to do with account type ${context.AccountType}`)
    }

    var name = context?.Name || ''
    var userModel = new UserModel(name, roles, publicConfiguration, wldlConfiguration, homeRoute)
    return userModel
  }

  private static buildWldlConfiguration(context: UserAccountDto): WldlConfiguration {
    return new WldlConfiguration(context?.IsWLDLAdminAccount ?? false)
  }

  private publishAuthStateChange(authenticationState: AuthenticationState): void {
    this.eventBus.next(
      'authentication-state-changed',
      new AuthenticationStateChangedEvent(authenticationState)
    )
  }

  private publishUserModelChange(baseResponse: BaseResponse | null): void {
    var userModel = baseResponse ? CurrentUserService.getUserModel(baseResponse) : null
    this.eventBus.next('user-model-changed', new UserModelChangedEvent(userModel))
  }
}
