import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-tier-1-2',
  templateUrl: './tier-1-2.component.html',
  styleUrls: ['./tier-1-2.component.scss']
})
export class Tier1And2Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
