import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-access-restrictions',
  templateUrl: './access-restrictions.component.html',
  styleUrls: ['./access-restrictions.component.scss']
})
export class AccessRestrictionsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
