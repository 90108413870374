/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PIStatisticDto { 
    Id?: number;
    LoadDate?: string;
    Level?: number;
    FromRegionId?: number;
    ToRegionId?: number;
    FromSubRegionId?: number | null;
    ToSubRegionId?: number | null;
    FromId?: number;
    ToId?: number;
    FromName?: string | null;
    ToName?: string | null;
    ContainerType?: string | null;
    CurrMonth?: number | null;
    PrevMonth?: number | null;
    MonthPctChange?: number | null;
    PrevYearMonth?: number | null;
    YearMonthPctChange?: number | null;
    ShippingLineCount?: number;
    CreatedBy?: string | null;
    CreatedDate?: string | null;
}

