import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { WldlSharedModule } from '../../shared/wldl-shared.module'
import { VolumeDataComponent } from './components/volume-data/volume-data.component'
import { VolumeDataRoutingModule } from './volume-data-routing.module'

@NgModule({
  imports: [CommonModule, VolumeDataRoutingModule, WldlSharedModule],
  declarations: [VolumeDataComponent]
})
export class VolumeDataModule {}
