<div class="element-outline-marking">
  <!-- <h3>{{ this.constructor.name }}</h3> -->

  <table class="table">
    <thead>
      <tr>
        <th>id</th>
        <th>email</th>
        <th>name</th>
        <th>type</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let account of accounts">
        <td>{{ account.Id }}</td>
        <td>{{ account.Email }}</td>
        <td>{{ account.Name }}</td>
        <td>{{ account.AccountType }}</td>
        <td>
          <button
            type="button"
            (click)="onUserSelected(account.Id, account.AccountType)"
            class="btn btn-sm btn-outline-dark">
            impersonate {{ account.FirstName }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
