/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface XmlImportFileDto { 
    Id?: number;
    ShippingLineId?: number;
    Filename?: string | null;
    Path?: string | null;
    IsValidXml?: boolean;
    Month?: number | null;
    Year?: number | null;
    ImportedDate?: string | null;
    NumberOfRows?: number;
    DryCount?: number | null;
    ReeferCount?: number | null;
    DryTotal?: number | null;
    ReeferTotal?: number | null;
    IncludesDG?: boolean;
    MappedSuccessPct?: number | null;
    RejectedPct?: number | null;
    StatusId?: number;
    IsCurrent?: boolean;
    CreatedDate?: string;
    UpdatedDate?: string;
    Status?: string | null;
    CarrierCode?: string | null;
    Carrier?: string | null;
    TEUCount?: number;
    TEUTotal?: number;
}

