import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component'
import { PageNotAllowedComponent } from './components/page-not-allowed/page-not-allowed.component'
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component'
import { AreaMenuComponent } from './components/route-menu/area-menu.component'
import { HeaderMenuComponent } from './components/route-menu/header-menu.component'
import { ServerErrorComponent } from './components/server-error/server-error.component'
import { LoadingDirective } from './directives/loading.directive'
import { RlaPageDirective } from './directives/router-link-active.directive'

@NgModule({
  imports: [CommonModule, RouterModule],

  declarations: [
    PageNotFoundComponent,
    PageNotAllowedComponent,
    RlaPageDirective,
    LoadingDirective,
    LoadingSpinnerComponent,
    ServerErrorComponent,
    AreaMenuComponent,
    HeaderMenuComponent
  ],
  exports: [LoadingDirective, AreaMenuComponent, HeaderMenuComponent]
})
export class WldlSharedModule {}
