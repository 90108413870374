/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Tier3NCLDataRow { 
    YYYY?: number;
    Location?: string | null;
    IsMonthTotal?: boolean;
    M1_TEU?: number;
    M1_TEU_RD?: number;
    M1_TEU_NDD?: number;
    M1_TEU_E?: number;
    M1_TEU_NDD_P?: number;
    M1_TEU_E_P?: number;
    M2_TEU?: number;
    M2_TEU_RD?: number;
    M2_TEU_NDD?: number;
    M2_TEU_E?: number;
    M2_TEU_NDD_P?: number;
    M2_TEU_E_P?: number;
    M3_TEU?: number;
    M3_TEU_RD?: number;
    M3_TEU_NDD?: number;
    M3_TEU_E?: number;
    M3_TEU_NDD_P?: number;
    M3_TEU_E_P?: number;
    Q1_TEU?: number;
    Q1_TEU_RD?: number;
    Q1_TEU_NDD?: number;
    Q1_TEU_E?: number;
    Q1_TEU_NDD_P?: number;
    Q1_TEU_E_P?: number;
    M4_TEU?: number;
    M4_TEU_RD?: number;
    M4_TEU_NDD?: number;
    M4_TEU_E?: number;
    M4_TEU_NDD_P?: number;
    M4_TEU_E_P?: number;
    M5_TEU?: number;
    M5_TEU_RD?: number;
    M5_TEU_NDD?: number;
    M5_TEU_E?: number;
    M5_TEU_NDD_P?: number;
    M5_TEU_E_P?: number;
    M6_TEU?: number;
    M6_TEU_RD?: number;
    M6_TEU_NDD?: number;
    M6_TEU_E?: number;
    M6_TEU_NDD_P?: number;
    M6_TEU_E_P?: number;
    Q2_TEU?: number;
    Q2_TEU_RD?: number;
    Q2_TEU_NDD?: number;
    Q2_TEU_E?: number;
    Q2_TEU_NDD_P?: number;
    Q2_TEU_E_P?: number;
    M7_TEU?: number;
    M7_TEU_RD?: number;
    M7_TEU_NDD?: number;
    M7_TEU_E?: number;
    M7_TEU_NDD_P?: number;
    M7_TEU_E_P?: number;
    M8_TEU?: number;
    M8_TEU_RD?: number;
    M8_TEU_NDD?: number;
    M8_TEU_E?: number;
    M8_TEU_NDD_P?: number;
    M8_TEU_E_P?: number;
    M9_TEU?: number;
    M9_TEU_RD?: number;
    M9_TEU_NDD?: number;
    M9_TEU_E?: number;
    M9_TEU_NDD_P?: number;
    M9_TEU_E_P?: number;
    Q3_TEU?: number;
    Q3_TEU_RD?: number;
    Q3_TEU_NDD?: number;
    Q3_TEU_E?: number;
    Q3_TEU_NDD_P?: number;
    Q3_TEU_E_P?: number;
    M10_TEU?: number;
    M10_TEU_RD?: number;
    M10_TEU_NDD?: number;
    M10_TEU_E?: number;
    M10_TEU_NDD_P?: number;
    M10_TEU_E_P?: number;
    M11_TEU?: number;
    M11_TEU_RD?: number;
    M11_TEU_NDD?: number;
    M11_TEU_E?: number;
    M11_TEU_NDD_P?: number;
    M11_TEU_E_P?: number;
    M12_TEU?: number;
    M12_TEU_RD?: number;
    M12_TEU_NDD?: number;
    M12_TEU_E?: number;
    M12_TEU_NDD_P?: number;
    M12_TEU_E_P?: number;
    Q4_TEU?: number;
    Q4_TEU_RD?: number;
    Q4_TEU_NDD?: number;
    Q4_TEU_E?: number;
    Q4_TEU_NDD_P?: number;
    Q4_TEU_E_P?: number;
    YY_TEU?: number;
    YY_TEU_RD?: number;
    YY_TEU_NDD?: number;
    YY_TEU_E?: number;
    YY_TEU_NDD_P?: number;
    YY_TEU_E_P?: number;
}

