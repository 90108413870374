import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { WldlSharedModule } from '../../shared/wldl-shared.module'
import { DownloadComponent } from './components/download/download.component'
import { DownloadRoutingModule } from './download-routing.module'

@NgModule({
  imports: [CommonModule, DownloadRoutingModule, WldlSharedModule],
  declarations: [DownloadComponent]
})
export class DownloadModule {}
