<div class="element-outline-marking">
  <h2>{{ this.constructor.name }}</h2>

  <app-area-menu [routes]="routes"></app-area-menu>

  <div title="Level 2 Router Outlet" class="router-outlet">
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
