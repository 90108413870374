import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { PageNotAllowedComponent } from '../../shared/components/page-not-allowed/page-not-allowed.component'
import { PageNotFoundComponent } from '../../shared/components/page-not-found/page-not-found.component'
import { AutoNclSubmissionsComponent } from './components/auto-ncl-submissions/auto-ncl-submissions.component'
import { AutoNclVolumeComponent } from './components/auto-ncl-volume/auto-ncl-volume.component'
import { EstimatingComponent } from './components/estimating/estimating.component'
import { Tier1And2Component } from './components/tier-1-2/tier-1-2.component'
import { Tier3Component } from './components/tier-3/tier3.component'
import { Tier4DetailComponent } from './components/tier-4-detail/tier4-detail.component'

const estimatingRoutes: Routes = [
  {
    path: '',
    component: EstimatingComponent,
    children: [
      {
        path: 'auto-ncl-submissions',
        title: 'Auto NCL Submissions',
        component: AutoNclSubmissionsComponent
      },
      {
        path: 'auto-ncl-vol',
        title: 'Auto NCL Vol',
        component: AutoNclVolumeComponent
      },
      {
        path: 'tier-1-2',
        title: 'Tier 1/2',
        component: Tier1And2Component
      },
      {
        path: 'tier-3',
        title: 'Tier 3',
        component: Tier3Component
      },
      {
        path: 'tier-4-detail',
        title: 'Tier 4 Detail',
        component: Tier4DetailComponent
      },

      { path: '', redirectTo: 'auto-ncl-submissions', pathMatch: 'full' },
      { path: 'not-allowed', component: PageNotAllowedComponent },
      { path: '**', component: PageNotFoundComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(estimatingRoutes)],
  exports: [RouterModule]
})
export class EstimatingRoutingModule {}
