/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RejectedXmlRowDto { 
    Id?: number;
    XmlImportFileId?: number;
    ShippingLineId?: number;
    BusinessContextId?: number | null;
    ConsortiumId?: number | null;
    LoadDate?: string;
    ContainerType?: string | null;
    SourceOrigin?: string | null;
    SourceLoadPort?: string | null;
    SourceDischargePort?: string | null;
    SourceDestination?: string | null;
    TEU?: number;
    RatePerTEU?: number | null;
    Currency?: string | null;
    USDRatePerTEU?: number | null;
    TEUxUSDRate?: number | null;
    ParsedOrigin?: string | null;
    ParsedLoadPort?: string | null;
    ParsedDischargePort?: string | null;
    ParsedDestination?: string | null;
    FromLocationCode?: string | null;
    ToLocationCode?: string | null;
    FromLocationId?: number | null;
    ToLocationId?: number | null;
    IsRejected?: boolean;
    RejectReasonId?: number | null;
    RejectedData?: string | null;
    IsCurrent?: boolean;
    CreatedDate?: string;
    CreatedBy?: string | null;
    UpdatedDate?: string;
    UpdatedBy?: string | null;
    IsDG?: boolean;
    DataReleaseDate?: string | null;
    SafetyMechanismId?: number | null;
    LoadDateText?: string | null;
    LoadYear?: number;
    LoadMonth?: number;
    LoadMonthName?: string | null;
    BusinessContextName?: string | null;
    ShippingLine?: string | null;
    FromLocationName?: string | null;
    ToLocationName?: string | null;
    FromLocationUnmapped?: boolean;
    ToLocationUnmapped?: boolean;
    RejectReasonText?: string | null;
    ShippingLineName?: string | null;
    Filename?: string | null;
}

