/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MonthlyTradeStatisticsRow { 
    Level?: number;
    FromRegionId?: number;
    ToRegionId?: number;
    FromSubRegionId?: number;
    ToSubRegionId?: number;
    FromName?: string | null;
    ToName?: string | null;
    LoadDate?: string;
    CurrMonthVol_All?: number;
    CurrMonthVol_Dry?: number;
    CurrMonthVol_Reefer?: number;
    CurrMonthVol_All_Pct?: number;
    CurrMonthVol_Dry_Pct?: number;
    CurrMonthVol_Reefer_Pct?: number;
    CurrMonthPI_All?: number;
    CurrMonthPI_Dry?: number;
    CurrMonthPI_Reefer?: number;
    CurrMonthPI_All_Pct?: number;
    CurrMonthPI_Dry_Pct?: number;
    CurrMonthPI_Reefer_Pct?: number;
    Vol_All_Purchased?: boolean;
    Vol_Dry_Purchased?: boolean;
    Vol_Reefer_Purchased?: boolean;
    PI_All_Purchased?: boolean;
    PI_Dry_Purchased?: boolean;
    PI_Reefer_Purchased?: boolean;
}

