/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AggregatedVolumeDataRow { 
    IsOutputCell?: boolean;
    FromLocation?: string | null;
    FromCountry?: string | null;
    FromLocationId?: number | null;
    FromLocationIsOtherDataCell?: boolean;
    LoadYear?: number | null;
    LoadMonth?: number | null;
    ToLocation?: string | null;
    ToCountry?: string | null;
    ToLocationId?: number;
    SafetyMechanismId?: number | null;
    DataReleaseDate?: string | null;
    ToLocationIsOtherDataCell?: boolean;
    TEUValue?: number;
    RowTotalAll?: number;
    ColumnTotalAll?: number;
    TEU_All?: number;
    TEU_Dry?: number;
    TEU_Reefer?: number;
    SMOtherTotal_All?: number;
    SMOtherTotal_Dry?: number;
    SMOtherTotal_Reefer?: number;
    IsSMOtherCell?: boolean;
    IsSMOtherOtherCell?: boolean;
    IsSMOtherRowTotalCell?: boolean;
    IsColumnTotalsRow?: boolean;
    IsSMOthersRow?: boolean;
    MyDataTEU_All?: number;
    MyDataTEU_Dry?: number;
    MyDataTEU_Reefer?: number;
    All_IsHidden?: boolean;
    Dry_IsHidden?: boolean;
    Reefer_IsHidden?: boolean;
    displayTEU_All?: string | null;
    displayTEU_Dry?: string | null;
    displayTEU_Reefer?: string | null;
    GroupByLabel?: string | null;
    SelectedContainerType?: string | null;
}

