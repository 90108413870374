/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VolumeDataSourceDto { 
    Id?: number;
    SourceType?: string | null;
    FromT1Id?: number | null;
    ToT1Id?: number | null;
    FromLocationId?: number | null;
    ToLocationId?: number | null;
    ContainerType?: string | null;
    TotalTEU?: number | null;
    FromDate?: string | null;
    ToDate?: string | null;
    ShippingLineId?: number | null;
    FromPorts?: string | null;
    FromPortsIncExc?: string | null;
    ToPorts?: string | null;
    ToPortsIncExc?: string | null;
    RecordStatus?: string | null;
    DummyDataGenerated?: boolean;
    MonthlyLiftingDataPrepared?: boolean | null;
    IsIncremental?: boolean;
    IsValid?: boolean;
    CreatedBy?: string | null;
    CreatedDate?: string | null;
    UpdatedBy?: string | null;
    UpdatedDate?: string | null;
    FromLocationNameT2?: string | null;
    ToLocationNameT2?: string | null;
    FromLocationName?: string | null;
    ToLocationName?: string | null;
    FromPortList?: string | null;
    FromExcludeIncludeLabel?: string | null;
    ToExcludeIncludeLabel?: string | null;
    ToPortList?: string | null;
    ShippingLineName?: string | null;
    GeneratedTEU?: number;
}

