<nav class="nav nav-pills">
  <div class="container-fluid">
    <a
      class="nav-link"
      *ngFor="let route of routesToDisplay | async"
      [routerLink]="route.path"
      routerLinkActive="active"
      appSetAriaCurrentOnActiveLink
      >{{ route.title }}</a
    >
  </div>
</nav>
