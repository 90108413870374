import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { WldlSharedModule } from '../../shared/wldl-shared.module'
import { CtsAdminComponent } from './components/cts-admin/cts-admin.component'
import { UserAccountsComponent } from './components/user-accounts/user-accounts.component'
import { CtsAdminRoutingModule } from './cts-admin-routing.module'

@NgModule({
  imports: [CommonModule, CtsAdminRoutingModule, WldlSharedModule],
  declarations: [CtsAdminComponent, UserAccountsComponent]
})
export class CtsAdminModule {}
