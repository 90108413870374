import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-tier4',
  templateUrl: './tier4.component.html',
  styleUrls: ['./tier4.component.scss']
})
export class Tier4Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
