import 'zone.js/plugins/zone-error'

export default {
  publicApiBasePath: 'https://public-api.cts-beta.dwis.co.uk',
  wldlApiBasePath: 'https://wldl-api.cts-beta.dwis.co.uk',
  publicAppUri: 'https://public.cts-beta.dwis.co.uk/auth-sso',
  wldlAppUri: 'https://cedar.cts-beta.dwis.co.uk/auth-sso',
  adminAppUri: 'https://cedar.cts-beta.dwis.co.uk/auth-sso',
  logoutUri: 'https://www.cts-beta.dwis.co.uk',
  authentication: {
    domain: 'auth.cts-beta.dwis.co.uk',
    clientId: 'W54PD4FycgPpomQfeMSQ15ckv0DdGqAP'
  }
}
