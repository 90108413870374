import { Injectable } from '@angular/core'
import { AuthConfig } from '@auth0/auth0-angular'

import currentAppSettings from './app-settings.default'
// subsitited at publication time for the appropriate configuration
import currentEnvironment from './environment.default'

// https://www.qualdesk.com/blog/2021/type-guard-for-string-union-types-typescript/
const accountTypes = ['P', 'C', 'W'] as const
export type AccountType = (typeof accountTypes)[number]

@Injectable({ providedIn: 'root' })
export class AppConfig {
  application!: {
    accountTypes: AccountType[]
  }
  production!: boolean
  publicApiBasePath!: string
  wldlApiBasePath!: string
  publicAppUri!: string
  wldlAppUri!: string
  adminAppUri!: string
  logoutUri!: string
  authentication!: AuthConfig
  anonymousEndpoints!: string[]
}

var baseConfig = {
  production: false,
  anonymousEndpoints: ['/ping', '/release-version', '/release/*'],
  authentication: {
    errorPath: '/error',
    useRefreshTokens: true,
    cacheLocation: 'localstorage' as const,
    authorizationParams: {
      audience: 'https://www.containerstatistics.com/cedar/api/v2',
      redirect_uri: window.location.origin
    }
  }
}
export const APP_CONFIG = {
  ...baseConfig,
  ...currentEnvironment,
  ...{
    authentication: {
      ...baseConfig.authentication,
      ...currentEnvironment.authentication
    },
    ...{
      application: { ...currentAppSettings }
    }
  }
}
