import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-tier2',
  templateUrl: './tier2.component.html',
  styleUrls: ['./tier2.component.scss']
})
export class Tier2Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
