import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-sub-region',
  templateUrl: './sub-region.component.html',
  styleUrls: ['./sub-region.component.scss']
})
export class SubRegionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
