import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-pre-release',
  templateUrl: './pre-release.component.html',
  styleUrls: ['./pre-release.component.scss']
})
export class PreReleaseComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
