/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DisplayOptions { 
    Years?: Array<number> | null;
    ImportExport?: Array<string> | null;
    ContainerTypes?: Array<string> | null;
    ShowSubregions?: boolean;
    ShowCountries?: boolean;
    ShowPorts?: boolean;
    ShowToLocations?: boolean;
    ShowImportExport?: boolean;
    ShowContainerTypes?: boolean;
}

