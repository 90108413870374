import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { PageNotAllowedComponent } from '../../shared/components/page-not-allowed/page-not-allowed.component'
import { PageNotFoundComponent } from '../../shared/components/page-not-found/page-not-found.component'
import { AccountDetailsComponent } from './components/account-details/account-details.component'
import { CodeLookupComponent } from './components/code-lookup/code-lookup.component'
import { MyAccountComponent } from './components/my-account/my-account.component'
import { OperatingRoutesComponent } from './components/operating-routes/operating-routes.component'

const myAccountRoutes: Routes = [
  {
    path: '',
    component: MyAccountComponent,
    children: [
      {
        path: 'account-details',
        title: 'Account Details',
        component: AccountDetailsComponent
      },
      {
        path: 'operating-routes',
        title: 'Operating Routes',
        component: OperatingRoutesComponent
      },
      {
        path: 'code-lookup',
        title: 'Code Lookup',
        component: CodeLookupComponent
      },

      { path: '', redirectTo: 'account-details', pathMatch: 'full' },
      { path: 'not-allowed', component: PageNotAllowedComponent },
      { path: '**', component: PageNotFoundComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(myAccountRoutes)],
  exports: [RouterModule]
})
export class MyAccountRoutingModule {}
