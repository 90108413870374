import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-auto-ncl-volume',
  templateUrl: './auto-ncl-volume.component.html',
  styleUrls: ['./auto-ncl-volume.component.scss']
})
export class AutoNclVolumeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
