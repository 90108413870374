import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-operating-routes',
  templateUrl: './operating-routes.component.html',
  styleUrls: ['./operating-routes.component.scss']
})
export class OperatingRoutesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
