import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-latest-pls',
  templateUrl: './latest-pls.component.html',
  styleUrls: ['./latest-pls.component.scss']
})
export class LatestPlsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
