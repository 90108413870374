/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Tier4NCLDataRow { 
    YYYY?: number;
    MM?: number;
    FromLocation?: string | null;
    ToLocation?: string | null;
    IsTotalCell?: boolean;
    TEU?: number;
    TEU_RD?: number;
    TEU_NDD?: number;
    TEU_E?: number;
    TEU_NDD_P?: number;
    TEU_E_P?: number;
}

