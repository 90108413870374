[
    {
        "CurrentReleaseMonth": "0001-01-01T00:00:00",
        "LatestUpdateDate": "0001-01-01T00:00:00",
        "ShippingLine": null,
        "BusinessContextId": null,
        "Customer": {
            "UserAccounts": [],
            "Subscriptions": [],
            "CustomReports": [],
            "NewsletterSubscriber": false,
            "DataSubscriber": false,
            "VolumeReportsSubscriber": false,
            "PriceIndicesSubscriber": false,
            "NumberOfVolumeReports": 0,
            "NumberOfPriceIndices": 0,
            "NumberOfCustomReports": 0,
            "NewsletterExpiryDate": null,
            "NewsletterStartDate": null,
            "AlertNewsletterExpiryDate": false,
            "NextSubscriptionExpiryDate": null,
            "AlertSubscriptionExpiryDate": false,
            "SubscriptionMinDate": null,
            "SubscriptionMaxDate": null,
            "Id": 1037,
            "CustomerName": "K Line Europe",
            "P3_CustomerId": 9241,
            "Address_1": null,
            "Address_2": null,
            "Address_3": null,
            "Address_4": null,
            "Country": null,
            "PostCode": null,
            "Phone": null,
            "Mobile": null,
            "IsDemoAccount": false,
            "CreatedBy": "script",
            "CreatedDate": "2017-02-24T15:58:00.993",
            "UpdatedBy": "script",
            "UpdatedDate": "2017-02-24T15:58:00.993"
        },
        "GroupName": null,
        "OperatingRoutes": null,
        "FromRegions": null,
        "ToRegions": null,
        "Auth0UserId": "",
        "UserList": null,
        "IsNew": false,
        "ActiveBusinessContextId": null,
        "IsShippingGroup": false,
        "WLDLGroupName": "",
        "WLDLMemberName": "",
        "WLDLMemberCode": "",
        "IsCTSAdminAccount": false,
        "IsPublicAccount": true,
        "IsFreeDataAccount": false,
        "IsCustomerAccount": true,
        "CustomerName": "K Line Europe",
        "IsCTSAccount": false,
        "IsWLDLAdminAccount": false,
        "IsWLDLAccount": false,
        "IsWLDLVolumesOnly": false,
        "AccountTypeLabel": "Public",
        "RegistrationRequired": false,
        "RegistrationIsComplete": false,
        "Id": 7909,
        "Auth0_UserId": null,
        "Email": "adam.bain@uk.kline.com",
        "Name": "Adam Bain",
        "Surname": null,
        "Phone": null,
        "CompanyName": null,
        "JobTitle": null,
        "BusinessSector": null,
        "BusinessEmail": null,
        "Address": null,
        "Country": null,
        "Interests": null,
        "Source": null,
        "RegistrationCompleted": false,
        "AccountType": "P",
        "ShippingLineId": null,
        "ViewDataAsGroup": false,
        "CustomerId": 1037,
        "IsPrimary": false,
        "EmailIsVerified": false,
        "IsSystemAdmin": false,
        "IsDisabled": false,
        "IPAddressMask": null,
        "CreatedDate": "2017-02-24T17:29:40.903",
        "CreatedBy": "script",
        "UpdatedDate": "2020-05-05T11:48:27.87",
        "UpdatedBy": "[SYSTEM]",
        "LastLoginDate": null,
        "IsUnsubscribed": false,
        "CTSNewsletter": true,
        "EmailConsent": false,
        "PhoneConsent": false,
        "ReleaseNotificationEmail": false
    },
    {
        "CurrentReleaseMonth": "0001-01-01T00:00:00",
        "LatestUpdateDate": "0001-01-01T00:00:00",
        "ShippingLine": {
            "Id": 19,
            "BusinessContextId": null,
            "Name": "MSC",
            "DisplayName": "MSC",
            "IsDisplayed": true,
            "Code": "MSC",
            "EmailDomain": null,
            "IsFullyContributing": true,
            "IsNCL": false,
            "IsActive": true,
            "Ignore": false,
            "VolumesOnly": false,
            "GeneratePriceIndices": true,
            "CreatedDate": "2016-04-21T12:32:46.21",
            "CreatedBy": "script",
            "UpdatedDate": "2018-06-14T12:21:37.293",
            "UpdatedBy": "Dom"
        },
        "BusinessContextId": null,
        "Customer": null,
        "GroupName": null,
        "OperatingRoutes": null,
        "FromRegions": null,
        "ToRegions": null,
        "Auth0UserId": "5c533ba99a45f042708e7d41",
        "UserList": null,
        "IsNew": false,
        "ActiveBusinessContextId": 19,
        "IsShippingGroup": false,
        "WLDLGroupName": "",
        "WLDLMemberName": "MSC",
        "WLDLMemberCode": "MSC",
        "IsCTSAdminAccount": false,
        "IsPublicAccount": false,
        "IsFreeDataAccount": false,
        "IsCustomerAccount": false,
        "CustomerName": "",
        "IsCTSAccount": false,
        "IsWLDLAdminAccount": false,
        "IsWLDLAccount": true,
        "IsWLDLVolumesOnly": false,
        "AccountTypeLabel": "WLDL",
        "RegistrationRequired": false,
        "RegistrationIsComplete": false,
        "Id": 12774,
        "Auth0_UserId": "auth0|5c533ba99a45f042708e7d41",
        "Email": "ahmed.farag@msc.com",
        "Name": "Ahmed Farag",
        "Surname": null,
        "Phone": null,
        "CompanyName": null,
        "JobTitle": null,
        "BusinessSector": null,
        "BusinessEmail": null,
        "Address": null,
        "Country": null,
        "Interests": null,
        "Source": null,
        "RegistrationCompleted": false,
        "AccountType": "W",
        "ShippingLineId": 19,
        "ViewDataAsGroup": false,
        "CustomerId": null,
        "IsPrimary": false,
        "EmailIsVerified": true,
        "IsSystemAdmin": false,
        "IsDisabled": false,
        "IPAddressMask": null,
        "CreatedDate": "2019-01-31T18:17:17",
        "CreatedBy": "DefaultAppPool",
        "UpdatedDate": "2020-05-05T11:48:27.87",
        "UpdatedBy": "[SYSTEM]",
        "LastLoginDate": "2020-02-15T07:07:14.797",
        "IsUnsubscribed": false,
        "CTSNewsletter": false,
        "EmailConsent": false,
        "PhoneConsent": false,
        "ReleaseNotificationEmail": false
    }
]