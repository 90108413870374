/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VolumeStatisticDto { 
    Id?: number;
    LoadDate?: string;
    Type?: string | null;
    Level?: number;
    FromRegionId?: number;
    ToRegionId?: number;
    FromSubRegionId?: number | null;
    ToSubRegionId?: number | null;
    FromId?: number;
    ToId?: number;
    FromName?: string | null;
    ToName?: string | null;
    CurrMonth_All?: number | null;
    PrevMonth_All?: number | null;
    MonthPctChange_All?: number | null;
    PrevYearMonth_All?: number | null;
    YearMonthPctChange_All?: number | null;
    CurrYTD_All?: number | null;
    PrevYTD_All?: number | null;
    YTDPctChange_All?: number | null;
    CurrMonth_Dry?: number | null;
    PrevMonth_Dry?: number | null;
    MonthPctChange_Dry?: number | null;
    PrevYearMonth_Dry?: number | null;
    YearMonthPctChange_Dry?: number | null;
    CurrYTD_Dry?: number | null;
    PrevYTD_Dry?: number | null;
    YTDPctChange_Dry?: number | null;
    CurrMonth_Reefer?: number | null;
    PrevMonth_Reefer?: number | null;
    MonthPctChange_Reefer?: number | null;
    PrevYearMonth_Reefer?: number | null;
    YearMonthPctChange_Reefer?: number | null;
    CurrYTD_Reefer?: number | null;
    PrevYTD_Reefer?: number | null;
    YTDPctChange_Reefer?: number | null;
    CreatedDate?: string | null;
    CreatedBy?: string | null;
    AverageMonthlyVolume?: number;
}

