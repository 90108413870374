import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Data, Router, UrlTree } from '@angular/router'
import { map, Observable } from 'rxjs'

import { PublicProduct } from '../current-user/models/public-product'
import { UserModel } from '../current-user/models/user.model'
import { UserRole } from '../current-user/models/user-role'
import { EventBus } from '../event-bus/event-bus'
import { CanCheckUserMenuItem } from './can-check-user-menu-item'
import { UserCondition } from './user-condition'

@Injectable({
  providedIn: 'root'
})
export class Guard implements CanActivate, CanCheckUserMenuItem {
  constructor(
    private eventBus: EventBus,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.eventBus.getObservable$('user-model-changed').pipe(
      map((event) => {
        return (
          this.checkMenuItem(event.payload, route.data || null) ||
          Guard.createSiblingUrlTree(route, this.router, 'not-allowed')
        )
      })
    )
  }
  public checkMenuItem(userModel: UserModel | null, routeData: Data | undefined): boolean {
    if (userModel == null) return false
    if (!routeData) return true
    var conditions = (routeData['conditions'] as Array<UserCondition>) || []
    var result = true
    conditions.every((condition) => {
      var outcome = condition(userModel)
      result &&= outcome
      return result
    })
    return result
  }

  public static isInRole(validRoles: UserRole[]): UserCondition {
    return (userModel) =>
      userModel.roles &&
      validRoles.some(
        (validRole) => typeof userModel.roles.find((role) => role === validRole) !== 'undefined'
      )
  }

  public static isRegistered(shouldBeRegistered: boolean): UserCondition {
    return (userModel) =>
      userModel.publicConfiguration &&
      userModel.publicConfiguration.RegistrationCompleted == shouldBeRegistered
  }

  public static hasProduct(product: PublicProduct, shouldHaveProduct: boolean): UserCondition {
    return (userModel) =>
      userModel.publicConfiguration &&
      (typeof userModel.publicConfiguration.Products.find((p) => p === product) !== 'undefined') ===
        shouldHaveProduct
  }

  // https://github.com/angular/angular/issues/22763
  private static createSiblingUrlTree(
    route: ActivatedRouteSnapshot,
    router: Router,
    leaf: string
  ): UrlTree {
    const urlSegments = route.pathFromRoot
      .map((snapshot) => snapshot.url)
      .reduce((acc, urlSegments) => acc.concat(urlSegments), [])
      .filter((urlSegment) => !!urlSegment)
    const currentUrl = urlSegments.map((urlSegment) => urlSegment.path)
    currentUrl.pop()
    const relativeUrl = currentUrl.concat([leaf])
    return router.createUrlTree(relativeUrl)
  }
}
