/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SubscriptionDownloadTaskDto { 
    Id?: number;
    SubscriptionDownloadRequestId?: number;
    SubscriptionId?: number;
    CustomReportId?: number | null;
    FromDate?: string | null;
    ToDate?: string | null;
    StatusId?: number;
    OutputFileName?: string | null;
    OutputFilePath?: string | null;
    ErrorText?: string | null;
    CreatedBy?: string | null;
    CreatedDate?: string | null;
    UpdatedBy?: string | null;
    UpdatedDate?: string | null;
    SubscriptionLabel?: string | null;
}

