import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { Route, Router } from '@angular/router'

import { CurrentUserService } from '../services/current-user/current-user.service'
import { AuthenticationState } from '../services/current-user/models/authentication-state'
import { UserRole } from '../services/current-user/models/user-role'
import { EventBus } from '../services/event-bus/event-bus'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public authenticationState = AuthenticationState.BUSY
  public userDescription: string = ''

  // eslint-disable-next-line
  public AuthenticationState: typeof AuthenticationState = AuthenticationState

  @ViewChild('corporateTemplate')
  public corporateHeaderTemplate!: TemplateRef<any>
  @ViewChild('loggedInContextTemplate')
  public loggedInContextTemplate!: TemplateRef<any>
  @ViewChild('impersonatingContextTemplate')
  public impersonatingContextTemplate!: TemplateRef<any>

  public liveHeaderTemplate: TemplateRef<any> | null = null
  public liveContextTemplate: TemplateRef<any> | null = null

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
    private eventBus: EventBus
  ) {}

  public get routes(): Route[] {
    return this.router.config
  }

  ngOnInit(): void {
    this.eventBus.handle({
      'authentication-state-changed': (authenticationState) => {
        this.authenticationState = authenticationState

        switch (authenticationState) {
          case AuthenticationState.LOGGED_OUT:
          case AuthenticationState.BUSY:
            return

          case AuthenticationState.LOGGED_IN:
            this.liveContextTemplate = this.loggedInContextTemplate
            break

          case AuthenticationState.IMPERSONATION_STARTED:
            this.liveContextTemplate = this.impersonatingContextTemplate
            this.router.navigate(['/'])
            break

          default:
            throw new Error(
              `Don't know what to do with authenticationState '${authenticationState}'`
            )
        }
      },

      'user-model-changed': (newUser) => {
        if (newUser == null) {
          return
        }

        var displayRoles: string[] = []
        newUser.roles.forEach((r) => {
          displayRoles.push(UserRole[r])
        })
        this.userDescription = `${newUser.name} (${displayRoles.join(', ')})`
        if (newUser.roles.find((r) => r == UserRole.CtsAdministrator))
          this.router.navigate(['/cts-admin/latest'])
      }
    })
  }

  logIn(): void {
    this.currentUserService.logIn()
  }

  logOut(): void {
    this.currentUserService.logOut()
  }

  revertImpersonation() {
    this.currentUserService.revertImpersonation()
  }
}
