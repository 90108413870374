import { PublicConfiguration } from './public-configuration.model'
import { UserRole } from './user-role'
import { WldlConfiguration } from './wldl-configuration.model'

export class UserModel {
  isInRole(requiredRole: UserRole): boolean {
    var roles = this.roles
    return roles && typeof roles.find((role) => role === requiredRole) !== 'undefined'
  }
  constructor(
    public readonly name: string,
    public readonly roles: UserRole[],
    public readonly publicConfiguration: PublicConfiguration,
    public readonly wldlConfiguration: WldlConfiguration,
    public readonly homeRoute: string
  ) {}
}
