/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export enum FormattedReportType {
    Customer_PriceIndex = 0,
    Customer_TradeVolume = 1,
    CTS_TopTenLocations = 2,
    Hackett_EuroVols = 3,
    ClarksonTopTen = 4,
    CTS_PreReleaseVariances = 5,
    NewsletterData = 6,
    Customer_PriceIndex_All = 7,
    Customer_CustomReport = 8
}

