import { Injectable } from '@angular/core'
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs'

import { CallbackHandler } from './callback-handler'
import { TopicKeys, TopicTypeMap } from './events'

export class BaseEvent<K> {
  public readonly timestamp: Date
  constructor(
    public type: TopicKeys,
    public payload: K
  ) {
    this.timestamp = new Date()
  }
}
type EventOfType<Type extends TopicKeys> = BaseEvent<TopicTypeMap[Type]>

type SubjectKeyMap = {
  [Type in TopicKeys]?: Subject<EventOfType<Type>>
}

export class BusSubscription {
  constructor(private subscriptions: Subscription[]) {}

  unsubscribe(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions.splice(0)
  }
}

@Injectable({ providedIn: 'root' })
export class EventBus {
  private observers: SubjectKeyMap = {}

  public next<Key extends TopicKeys>(type: Key, event: EventOfType<Key>): void {
    var observer = this.ensure(type)
    observer.next(event)
  }

  private ensure<Key extends TopicKeys>(type: Key): Subject<EventOfType<Key>> {
    if (!this.observers[type]) {
      this.observers = {
        ...this.observers,
        [type]: new ReplaySubject<EventOfType<Key>>(1)
      }
    }
    return this.observers[type]!
  }

  public getObservable$<Key extends TopicKeys>(type: Key): Observable<EventOfType<Key>> {
    return this.ensure(type).asObservable()
  }

  public handle(handler: CallbackHandler): BusSubscription {
    let subscriptions: Subscription[] = []

    var keys = Object.keys(handler) as TopicKeys[]

    keys.forEach((key) => {
      var observable = this.ensure(key)
      var callbackToExecute = handler[key] as any
      var subscription = observable.subscribe((event) => callbackToExecute(event.payload))
      subscriptions.push(subscription)
    })

    return new BusSubscription(subscriptions)
  }
}
