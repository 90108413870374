/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NCLDataForLineByYearDatarow { 
    ShippingLineName?: string | null;
    FromName?: string | null;
    ToName?: string | null;
    FromLocationId?: number;
    ToLocationId?: number;
    Y0_TEU_NCL_DRY?: number;
    Y0_TEU_NCL_REEFER?: number;
    Y1_TEU_NCL_DRY?: number;
    Y1_TEU_NCL_REEFER?: number;
    Y0_TEU_P3_DRY?: number;
    Y0_TEU_P3_REEFER?: number;
    Y1_TEU_P3_DRY?: number;
    Y1_TEU_P3_REEFER?: number;
    Y0_TEU_DD_DRY?: number;
    Y0_TEU_DD_REEFER?: number;
    Y1_TEU_DD_DRY?: number;
    Y1_TEU_DD_REEFER?: number;
}

