import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-latest',
  templateUrl: './latest.component.html',
  styleUrls: ['./latest.component.scss']
})
export class LatestComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
