import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from 'projects/cedar-wldl/src/services/current-user/current-user.service'

import sampleData from './user-accounts.sample.json'

@Component({
  selector: 'app-user-accounts',
  templateUrl: './user-accounts.component.html',
  styleUrls: ['./user-accounts.component.scss']
})
export class UserAccountsComponent implements OnInit {
  public accounts: any[] = sampleData

  constructor(private currentUserService: CurrentUserService) {}

  ngOnInit(): void {}

  onUserSelected(userId: number, accountType: 'W' | 'P'): void {
    this.currentUserService.startImpersonation(userId, accountType)
  }
}
