import { PublicProduct } from './public-product'

export class PublicConfiguration {
  static none: PublicConfiguration = new PublicConfiguration(false, [])

  constructor(
    public readonly RegistrationCompleted: boolean,
    public readonly Products: PublicProduct[]
  ) {}
}
