import { AuthenticationState } from '../current-user/models/authentication-state'
import { UserModel } from '../current-user/models/user.model'
import { BaseEvent } from './event-bus'

export type TopicKeys = keyof TopicTypeMap

export interface TopicTypeMap {
  'authentication-state-changed': AuthenticationState
  'user-model-changed': UserModel | null
  'user-profile-updated': void
}

export class AuthenticationStateChangedEvent extends BaseEvent<AuthenticationState> {
  constructor(payload: AuthenticationState) {
    super('authentication-state-changed', payload)
  }
}

export class UserModelChangedEvent extends BaseEvent<UserModel | null> {
  constructor(payload: UserModel | null) {
    super('user-model-changed', payload)
  }
}
export class UserProfileUpdatedEvent extends BaseEvent<void> {
  constructor() {
    super('user-profile-updated')
  }
}
