import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { UserRole } from '../services/current-user/models/user-role'
import { Guard } from '../services/guard/guard'
import { CtsAdminModule } from './areas/cts-admin/cts-admin.module'
import { DownloadModule } from './areas/download/download.module'
import { EstimatingModule } from './areas/estimating/estimating.module'
import { MyAccountModule } from './areas/my-account/my-account.module'
import { PriceIndicesModule } from './areas/price-indices/price-indices.module'
import { VolumeDataModule } from './areas/volume-data/volume-data.module'
import { PageNotAllowedComponent } from './shared/components/page-not-allowed/page-not-allowed.component'
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component'
import {
  SERVER_ERROR_ROUTE,
  ServerErrorComponent
} from './shared/components/server-error/server-error.component'

const appRoutes: Routes = [
  {
    path: 'my-account',
    title: 'MyAccount',
    loadChildren: () => MyAccountModule,
    canActivate: [Guard],
    data: { conditions: [Guard.isInRole([UserRole.Wldl, UserRole.CtsAdministrator])] }
  },
  {
    path: 'volume-data',
    title: 'Volume Data',
    loadChildren: () => VolumeDataModule,
    canActivate: [Guard],
    data: { conditions: [Guard.isInRole([UserRole.Wldl, UserRole.CtsAdministrator])] }
  },
  {
    path: 'price-indices',
    title: 'Price Indices',
    loadChildren: () => PriceIndicesModule,
    canActivate: [Guard],
    data: { conditions: [Guard.isInRole([UserRole.Wldl, UserRole.CtsAdministrator])] }
  },
  {
    path: 'download',
    title: 'Download',
    loadChildren: () => DownloadModule,
    canActivate: [Guard],
    data: { conditions: [Guard.isInRole([UserRole.Wldl, UserRole.CtsAdministrator])] }
  },
  {
    path: 'estimating',
    title: 'Estimating',
    loadChildren: () => EstimatingModule,
    canActivate: [Guard],
    data: { conditions: [Guard.isInRole([UserRole.CtsAdministrator])] }
  },
  {
    path: 'cts-admin',
    title: 'CTS Admin',
    loadChildren: () => CtsAdminModule,
    canActivate: [Guard],
    data: { conditions: [Guard.isInRole([UserRole.CtsAdministrator])] }
  },

  { path: '', redirectTo: '/my-account', pathMatch: 'full' },
  { path: SERVER_ERROR_ROUTE, component: ServerErrorComponent },
  { path: 'not-allowed', component: PageNotAllowedComponent },
  { path: '**', component: PageNotFoundComponent }
]

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
