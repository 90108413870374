/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MappedLocationCodeDto { 
    Id?: number;
    Code?: string | null;
    ShippingLineId?: number | null;
    UNLocationId?: number | null;
    UNLOCODE?: string | null;
    Type?: string | null;
    CodeChecked?: string | null;
    CreatedDate?: string;
    CreatedBy?: string | null;
    UpdatedDate?: string;
    UpdatedBy?: string | null;
}

