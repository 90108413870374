import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-reporting-locations',
  templateUrl: './reporting-locations.component.html',
  styleUrls: ['./reporting-locations.component.scss']
})
export class ReportingLocationsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
