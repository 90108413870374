import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { WldlSharedModule } from '../../shared/wldl-shared.module'
import { MyAccountComponent } from './components/my-account/my-account.component'
import { MyAccountRoutingModule } from './my-account-routing.module'

@NgModule({
  declarations: [MyAccountComponent],
  imports: [CommonModule, MyAccountRoutingModule, WldlSharedModule]
})
export class MyAccountModule {}
