import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { WldlSharedModule } from '../../shared/wldl-shared.module'
import { EstimatingComponent } from './components/estimating/estimating.component'
import { EstimatingRoutingModule } from './estimating-routing.module'

@NgModule({
  imports: [CommonModule, EstimatingRoutingModule, WldlSharedModule],
  declarations: [EstimatingComponent]
})
export class EstimatingModule {}
