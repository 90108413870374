/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DataRequest { 
    SubscriptionId?: number;
    SubscriptionFromDate?: string;
    SubscriptionToDate?: string;
    CurrentReleaseDate?: string;
    FromLocationId?: number;
    FromLevel?: number;
    ToLocationId?: number;
    ToLevel?: number;
    IsExports?: boolean;
    ContainerType?: string | null;
    FromDate?: string;
    ToDate?: string;
    ToYear?: number;
    ToMonth?: number;
    ContainerTypeLabel?: string | null;
}

