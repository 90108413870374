/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DownloadRequestBase { 
    OutputFilename?: string | null;
    OutputFilePath?: string | null;
    ZipFileName?: string | null;
    ZipFilePath?: string | null;
    ZipFileURL?: string | null;
    DownloadURLs?: Array<string> | null;
    Filenames?: Array<string> | null;
    ZipFileURLs?: Array<string> | null;
    ZipFilenames?: Array<string> | null;
}

