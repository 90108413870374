import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-price-indices-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
