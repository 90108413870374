import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { PageNotAllowedComponent } from '../../shared/components/page-not-allowed/page-not-allowed.component'
import { PageNotFoundComponent } from '../../shared/components/page-not-found/page-not-found.component'
import { ByTradeComponent } from './components/by-trade/by-trade.component'
import { CustomPlsComponent } from './components/custom-pls/custom-pls.component'
import { DownloadComponent } from './components/download/download.component'
import { LatestPlsComponent } from './components/latest-pls/latest-pls.component'
import { PriceIndicesComponent } from './components/price-indices/price-indices.component'
import { RegionComponent } from './components/region/region.component'
import { SubRegionComponent } from './components/sub-region/sub-region.component'

const priceIndicesRoutes: Routes = [
  {
    path: '',
    component: PriceIndicesComponent,
    children: [
      {
        path: 'latest-pls',
        title: 'Latest Pls',
        component: LatestPlsComponent
      },
      {
        path: 'by-trade',
        title: 'By Trade',
        component: ByTradeComponent
      },
      {
        path: 'region',
        title: 'Region',
        component: RegionComponent
      },
      {
        path: 'sub-region',
        title: 'SubRegion',
        component: SubRegionComponent
      },
      {
        path: 'custom-pls',
        title: 'Custom Pls',
        component: CustomPlsComponent
      },
      {
        path: 'download',
        title: 'Download',
        component: DownloadComponent
      },

      { path: '', redirectTo: 'latest-pls', pathMatch: 'full' },
      { path: 'not-allowed', component: PageNotAllowedComponent },
      { path: '**', component: PageNotFoundComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(priceIndicesRoutes)],
  exports: [RouterModule]
})
export class PriceIndicesRoutingModule {}
