/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomReportDto { 
    Id?: number;
    CustomerId?: number;
    Name?: string | null;
    Description?: string | null;
    SPName?: string | null;
    DataFromDate?: string;
    DataToDate?: string;
    IsActive?: boolean;
    CreatedBy?: string | null;
    CreatedDate?: string;
    UpdatedBy?: string | null;
    UpdatedDate?: string;
    Download?: boolean;
    IsValid?: boolean;
}

