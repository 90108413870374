import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Route } from '@angular/router'

@Component({
  selector: 'app-cts-admin',
  templateUrl: './cts-admin.component.html',
  styleUrls: ['./cts-admin.component.scss']
})
export class CtsAdminComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  public get routes(): Route[] {
    return this.route.routeConfig?.children || []
  }
  ngOnInit(): void {}
}
