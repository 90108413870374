import { Component, Injector, Input, OnInit } from '@angular/core'
import { Route } from '@angular/router'
import { UserModel } from 'projects/cedar-wldl/src/services/current-user/models/user.model'
import { EventBus } from 'projects/cedar-wldl/src/services/event-bus/event-bus'
import { CanCheckUserMenuItem } from 'projects/cedar-wldl/src/services/guard/can-check-user-menu-item'
import { Guard } from 'projects/cedar-wldl/src/services/guard/guard'
import { map, Observable, of } from 'rxjs'

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  providers: [{ provide: Guard, useClass: Guard }]
})
export class HeaderMenuComponent implements OnInit {
  constructor(
    private injector: Injector,
    private eventBus: EventBus
  ) {
    this.routes = null
  }

  @Input()
  public routes: Route[] | null

  public routesToDisplay: Observable<Route[]> = of([])

  ngOnInit(): void {
    this.routesToDisplay = this.routes
      ? this.eventBus
          .getObservable$('user-model-changed')
          .pipe(
            map((event) =>
              this.routes!.filter((route) => route.title && this.isAllowed(event.payload, route))
            )
          )
      : of([])
  }

  private isAllowed(userModel: UserModel | null, route: Route): boolean {
    var valid = true
    if (route.canActivate) {
      route.canActivate.forEach((token) => {
        var guard = this.injector.get(token as Function /*!*/)
        var validator = guard as CanCheckUserMenuItem
        if (!validator) {
          throw new Error('Guards passed to menu filter must be of type CanCheckUserMenuItem')
        }
        valid &&= validator.checkMenuItem(userModel, route.data)
        return valid
      })
    }
    return valid
  }
}
