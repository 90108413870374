import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { API_BASE_PATH } from './API_BASE_PATH'

@Injectable({ providedIn: 'root' })
export class ImpersonationInterceptor implements HttpInterceptor {
  public readonly userIdQueryParam = 'actor'
  private readonly storageKey = 'cedar-actor'
  private readonly apiHeaderName = 'X-Actor-Id'

  private impersonatedUserId: number = 0

  public get isImpersonating(): boolean {
    return this.impersonatedUserId != 0
  }

  constructor(@Inject(API_BASE_PATH) private apiBasePath: string) {
    var previous = localStorage.getItem(this.storageKey)
    if (!this.trySetActorId(previous)) {
      this.clearActorId()
    }
  }

  setImpersonationKeyFromQuery() {
    const urlParams = new URLSearchParams(document.location.search)
    const impersonationKey = urlParams.get(this.userIdQueryParam)
    if (!this.trySetActorId(impersonationKey)) {
      this.clearActorId()
    }
  }

  trySetActorId(value: any): boolean {
    const userId = Number(value) || 0
    if (userId) {
      this.impersonatedUserId = userId
      localStorage.setItem(this.storageKey, userId.toString())
      return true
    }
    return false
  }

  clearActorId() {
    this.impersonatedUserId = 0
    localStorage.removeItem(this.storageKey)
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var request = req
    if (req.url.startsWith(this.apiBasePath) && this.isImpersonating) {
      request = req.clone({
        headers: req.headers.append(this.apiHeaderName, this.impersonatedUserId.toString())
      })
    }

    return next.handle(request)
  }
}
